import React from 'react';
import businessStageDescriptions from './businessStageDescriptions';
import '../../styles/Form.css';
import { categories } from './categories';

const Form = ({ 
    fields, 
    handleInput,
    handlePreMoneyValuation, 
    toggleCheckbox, 
    state, 
    validateInput, 
    displayError,
    locationMatches,
}) => {
    const stageDescriptions = businessStageDescriptions[state.businessType];

    return (
        <form>
            {fields.map( (field, index) => {
                const inputNum = index;
                return (
                    field.type === 'text' || field.type === 'email' || field.type === 'password' || field.type === 'number'
                      ? <div key={field.id} className='inputField'>
                          <input 
                                type={field.type} 
                                id={field.id}
                                value={state[field.id]}
                                min={field.min}
                                max={field.max} 
                                className='text-inputs' 
                                placeholder={field.placeholder} 
                                onChange={handleInput}
                                onBlur={ e => {field.errorMessage && validateInput(e, index)}} />
                            {field.showError && <p className='error'>{field.errorMessage}</p>}
                         </div>                      
                        :
                        <div className={field.id ==='categories' ? 'catInputField' :'inputField'} key={field.id}>
                            <div className=''>
                                {field.id === 'categories' && <div className='cat-count'>Selected: {Object.keys(state.userCats).length}</div>}
                                <p className='choose'>{field.choose}</p>
                            </div>
                            {field.id === 'categories' 
                                ? <div className='' id={field.id}>
                                    {field.showError && <p className='error'>{field.errorMessage}</p>}
                                    <div className='overflow-auto categories'>
                                        {field.options && field.options.map( option => (
                                            <button 
                                            className={state.userCats.indexOf(option.name) !== -1 ? 'active-item cat-spacing' : 'cat-spacing'}
                                            id={option.name} 
                                            value={option}
                                            onClick={(e) => {
                                                    toggleCheckbox(e);
                                                    if (field.showError) {
                                                        displayError(false, inputNum);
                                                    }
                                                }}
                                                key={option.name}
                                            >
                                                {option.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>
                                : field.id === 'preMoneyValuation' ?
                                <div className='' id={field.id}>
                                    {field.showError && <p className='error'>{field.errorMessage}</p>}
                                    <div className='long-list'>
                                        <div className='overflow-auto list-group'>
                                            {stageDescriptions && stageDescriptions.map( stage => (
                                                <button 
                                                className={state[field.id] === stage.stage ? 'list-group-item active-stage' : 'list-group-item'}
                                                id={stage.stage} 
                                                value={stage.stage}
                                                key={stage.stage}
                                                onClick={(e) => {
                                                        handlePreMoneyValuation(e);
                                                        if (field.showError) {
                                                            displayError(false, inputNum);
                                                        }
                                                    }}
                                                >
                                                <div className='stage-title' id={stage.stage}>{stage.title}</div>
                                                <div className='stage-description' id={stage.stage}>{stage.description}</div>
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                                : field.id === 'customerType' || field.id === 'productType' ?
                                <div className='' id={field.id}>
                                    {field.showError && <p className='error'>{field.errorMessage}</p>}
                                    <div className='overflow-auto list-group'>
                                        {field.options && field.options.map( option => (
                                            <button 
                                            className={state[option.id] === true ? 'list-group-item active-item' : 'list-group-item'}
                                            id={option.id} 
                                            value={option}
                                            key={option.id}
                                            onClick={(e) => {
                                                    toggleCheckbox(e);
                                                    if (field.showError) {
                                                        displayError(false, inputNum);
                                                    }
                                                }}
                                            >
                                            {option.name}
                                            </button>
                                        ))}
                                    </div>
                                </div>

                                :
                                <div className='' id={field.id}>
                                    {field.showError && <p className='error'>{field.errorMessage}</p>}
                                    <div className={field.id !== 'businessType' ? 'long-list' : ''}> 
                                        <div className='overflow-auto list-group'>
                                            {field.options && field.options.map( option => (
                                                <button 
                                                className={state[field.id] === option ? 'list-group-item active-item' : 'list-group-item'}
                                                id={field.id} 
                                                value={option}
                                                key={option}
                                                onClick={(e) => {
                                                        handleInput(e);
                                                        if (field.showError) {
                                                            displayError(false, inputNum);
                                                        }
                                                    }}
                                                >
                                                {option}
                                                </button>
                                            ))}
                                        </div>
                                    </div>
                                </div>

                            }
                        </div>
                )
            })}
        </form>
    )
}

export default Form;