import React from 'react';
import { Switch, Route} from 'react-router-dom';
import '../styles/App.scss';
import InvestorList from './widgets/InvestorList';
import Success from './widgets/Success';
import ErrorPage from './widgets/ErrorPage';
import Welcome from './widgets/Welcome';
import Results from './widgets/Results';
import Share from './widgets/Share';
import new_stack_logo from '../images/new_stack_logo.png';

function App() {
  return (
    <div className='container-flex h-100'>
      <div className='d-flex justify-content-center'>
            <div className='row full-width align-items-center justify-content-center'>
              <div className='flex-item d-none full-width'></div>

              <Switch>
                <Route exact path='/' component={Welcome}/>
                <Route exact path='/investorForm' component={InvestorList} />
                <Route exact path='/results' component={Results} />
                <Route exact path='/share' component={Share} />
                <Route exact path='/success' component={Success} />
                <Route exact path='/error' component={ErrorPage} />
                <Route exact path='/privacy-policy' component={() => {
                  window.location.href = 'https://airtable.com/shrFSJt42yjh0XIv1'
                  return null;
                }} />
              </Switch>


              <div className='flex-item d-none full-width'></div>

              <div className='full-ratchet flex-item d-none d-sm-block'>
                  <h6 style={{fontStyle: 'italic', marginBottom: '5px', fontSize: "1em"}}>Powered by</h6>
                  <img className='logo' src={new_stack_logo} alt='New Stack Logo' />
                  <a className='logo-text' href='https://www.newstack.vc/' target='_blank' rel='noopener noreferrer'>
                    <h6 className='logo-text'>newstack.vc</h6>
                  </a>
                  <a className='logo-text' href='https://fullratchet.net/' target='_blank' rel='noopener noreferrer'>
                    <h6 className='logo-text'>fullratchet.net</h6>
                  </a>
              </div>

            </div>
        </div>
	  </div>
  );
}

export default App;
