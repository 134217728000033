export const categories = [
    {id: "Field 41", name: "3D Printing" },
    {id: "Field 42", name: "AdTech"},
    {id: "Field 43", name: "AgTech"},
    {id: "Field 44", name: "Analytics"},
    {id: "Field 45", name: "AI / ML"},
    {id: "Field 47", name: "AR / VR"},
    {id: "Field 46", name: "Audio"},
    {id: "Field 48", name: "Automation"},
    {id: "Field 49", name: "Autonomous Vehicles"},
    {id: "Field 50", name: "Beauty / Fashion"},
    {id: "Field 51", name: "Big Data"},
    {id: "Field 52", name: "Biotech"},
    {id: "Field 53", name: "Blockchain"},
    {id: "Field 57", name: "BuiltTech / Construction"},
    {id: "Field 54", name: "Cannabis"},
    {id: "Field 55", name: "Cleantech"},
    {id: "Field 56", name: "Cloud Infrastructure"},
    {id: "Field 60", name: "Crypto"},
    {id: "Field 61", name: "Cybersecurity"},
    {id: "Field 59", name: "D2C / CPG"},
    {id: "Field 62", name: "Data Services"},
    {id: "Field 63", name: "Dating"},
    {id: "Field 64", name: "Developer Tools"},
    {id: "Field 65", name: "Deep Tech"},
    {id: "Field 67", name: "Digital Storage"},
    {id: "Field 69", name: "E-commerce"},
    {id: "Field 70", name: "EdTech"},
    {id: "Field 71", name: "Energy"},
    {id: "Field 117", name: "Enterprise SaaS"},
    {id: "Field 72", name: "Entertainment"},
    {id: "Field 73", name: "Environmental"},
    {id: "Field 74", name: "FemTech / Woman-led"},
    {id: "Field 75", name: "FinTech"},
    {id: "Field 76", name: "FoodTech"},
    {id: "Field 127", name: "Freelance / Gig Economy"},
    {id: "Field 77", name: "FrontierTech"},
    {id: "Field 58", name: "Future of Work"},
    {id: "Field 78", name: "Gaming / Esports"},
    {id: "Field 79", name: "Govtech"},
    {id: "Field 80", name: "Hardtech / Hardware"},
    {id: "Field 66", name: "Healthcare / Digital Health"},
    {id: "Field 81", name: "Hospitality"},
    {id: "Field 82", name: "HR"},
    {id: "Field 83", name: "Impact"},
    {id: "Field 84", name: "Industrial"},
    {id: "Field 85", name: "IT"},
    {id: "Field 86", name: "InsurTech / Insurance"},
    {id: "Field 87", name: "IoT"},
    {id: "Field 88", name: "Legal"},
    {id: "Field 89", name: "Life Sciences / Pharma"},
    {id: "Field 90", name: "Location-based services"},
    {id: "Field 91", name: "Logistics / Transportation"},
    {id: "Field 92", name: "Manufacturing"},
    {id: "Field 93", name: "Marketing / Martech"},
    {id: "Field 94", name: "Marketplace / Platform"},
    {id: "Field 95", name: "Materials Science"},
    {id: "Field 96", name: "Med Device"},
    {id: "Field 97", name: "Media / Content"},
    {id: "Field 98", name: "Messaging"},
    {id: "Field 100", name: "Metered Service"},
    {id: "Field 99", name: "Mining"},
    {id: "Field 101", name: "Minority-Led"},
    {id: "Field 102", name: "Mobile"},
    {id: "Field 127", name: "Mobility"}, // mapped from 49
    {id: "Field 103", name: "Mortgage"},
    {id: "Field 104", name: "Nanotechnology"},
    {id: "Field 105", name: "Network Infrastructure"},
    {id: "Field 106", name: "Oil & Gas"},
    {id: "Field 107", name: "Open Source"},
    {id: "Field 108", name: "P2P / Peer to Peer"},
    {id: "Field 109", name: "Payments"},
    {id: "Field 110", name: "Pet Technology"},
    {id: "Field 111", name: "Physical Storage"},
    {id: "Field 128", name: "PropTech / Real Estate"}, // mapped from 57
    {id: "Field 112", name: "Publishing"},
    {id: "Field 68", name: "Remote Work"},
    {id: "Field 113", name: "Restaurant"},
    {id: "Field 114", name: "Retail"},
    {id: "Field 115", name: "Ridesharing"},
    {id: "Field 116", name: "Robotics & Drones"},
    {id: "Field 118", name: "Sales & CRM"},
    {id: "Field 119", name: "Search"},
    {id: "Field 120", name: "SMB"},
    {id: "Field 121", name: "Social"},
    // {id: "Field 122", name: "Software"},
    {id: "Field 123", name: "Space"},
    {id: "Field 130", name: "SportsTech"},
    {id: "Field 129", name: "Supply Chain / Procurement"}, // mapped from Logistics / Transportation 92
    {id: "Field 124", name: "Travel"},
    {id: "Field 125", name: "User Generated Content"},
    {id: "Field 126", name: "Wearables"},
];

export default categories;