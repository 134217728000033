import React, { Component } from 'react';
import Form from '../common/Form';
import CategoryRank from './CategoryRank';
import '../../styles/Form.css';
import '../../styles/InvestorList.css';
import categories from '../common/categories';
import constraints from '../common/constraints';
import update from 'immutability-helper';
import { validate } from 'validate.js';
import axios from 'axios';
import '../../../node_modules/animate.css';

class InvestorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            animation: 'animate__fadeIn animate__slow',
            company: this.props.location.state ? this.props.location.state.startupName : '',
            oneLiner: '',
            location: '',
            preMoneyValuation: '',
            b2b: false,
            b2c: false,
            b2b2c: false,
            hardware: false,
            software: false,
            nonTech: false,
            userCats: [],
            significantCats: [],
            businessType: '',
            email: '',
            step: 0,
            loading: false,
            fields: [
                [
                    {id: 'location', header: 'Which city is closest to your headquarters?', errorMessage: 'Select a Location', showError: false, choose: 'Choose one', options: [
                        'Albuquerque', 'Ann Arbor', 'Atlanta', 'Austin', 'Baltimore', 'Boise', 'Boston', 'Buffalo', 'Calgary', 
                        'Charlotte', 'Chattanooga', 'Chicago', 'Cincinnati', 'Cleveland', 'Columbus', 'Dallas', 
                        'Denver', 'Detroit', 'Halifax', 'Houston', 'Indianapolis', 'Jacksonville', 'Kansas City', 'Las Vegas',
                        'Little Rock', 'Los Angeles', 'Louisville', 'Memphis', 'Miami', 'Milwaukee', 
                        'Minneapolis', 'Montgomery', 'Montreal', 'Nashville', 'New Orleans', 'New York City', 'Oklahoma City', 'Omaha', 'Orlando', 'Philadelphia', 
                        'Pittsburgh', 'Phoenix/Scottsdale', 'Portland', 'Provo', 'Raleigh-Durham', 'Salt Lake City', 'San Diego', 'San Francisco', 
                        'Seattle', 'St. Louis', 'Toronto', 'Tulsa', 'Vancouver', 'Washington DC', 'International'
                    ]},
                ],
                [
                    {id: 'customerType', header: 'What best describes your customer type?', errorMessage: 'Select a Customer Type', showError: false, choose: 'Choose one', options: [
                        {id: 'b2b', name: 'B2B'}, {id: 'b2c', name: 'B2C'}, {id: 'b2b2c', name: 'B2B2C'}
                    ]},
                ],
                [
                    {id: 'productType', header: 'Which best describes your product type?', errorMessage: 'Select a Product Type', showError: false, choose: 'Choose one or more', options: [
                        {id: 'software', name: 'Software'}, {id: 'hardware', name: 'Hardware (including subscription models)'}, {id: 'nonTech', name: 'Non-Tech'}, 
                    ]},

                ],
                [
                    {id: 'categories', header: 'Which categories best describe your start up?', errorMessage: 'Please select between 2 - 10 categories', showError: false, choose: 'Choose between 2 - 10 categories', options: categories},
                ],
                [
                    {id: 'categoryOrder', header: 'Which categories are most significant?', errorMessage: '', showError: false},
                ],
                [
                    {id: 'businessType', header: 'Which best describes your business type?', errorMessage: 'Select a business type', showError: false, choose: 'Choose one', options: [
                        'Enterprise SaaS', 'Consumer SaaS', 'Marketplace', 'DeepTech / Hardware', 'Consumer User Growth'
                    ]},
                ],
                [
                    {id: 'preMoneyValuation', header: 'Which best describes the stage of your company?', errorMessage: 'Select a company stage', showError: false, choose: 'Choose one',},
                ],
                [
                    {id: 'oneLiner', type: 'text', header: 'Provide a one sentence description of your business:', errorMessage: 'Please enter a description', showError: false}
                ],
                [
                    {id: 'email', type: 'email', header: 'Enter your Email', placeholder: 'Type your answer here', errorMessage: 'Enter a valid email', showError: false},
                ],
            ],
        }
        this.handleInput = this.handleInput.bind(this);
        this.handlePreMoneyValuation = this.handlePreMoneyValuation.bind(this);
        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.switchStep = this.switchStep.bind(this);
        this.validateInput = this.validateInput.bind(this);
        this.onEnterPress = this.onEnterPress.bind(this);
        this.handleSignificantCats = this.handleSignificantCats.bind(this);
        this.submitForm = this.submitForm.bind(this);
    }

    handleInput(e) {
        e.preventDefault();
        let { value, type, id } = e.target;
        if(e.target.id === 'oneLiner'){
            this.setState({oneLiner: e.target.value})
        } else {
            this.setState({[id]: type === 'number' ? parseFloat(value) : type === 'email' ? value.toLowerCase() : value});
        }
    }

    handlePreMoneyValuation(e) {
        e.preventDefault();
        this.setState({preMoneyValuation: e.target.id});
    }

    async toggleCheckbox(e) {
        e.preventDefault(e);
        let { id } = e.target;
        if (id === 'hardware' || id === 'software' || id === 'nonTech') {
            this.setState({[id]: !this.state[id]});
        } else if (id ==='b2b') {
            this.setState({ b2b: !this.state[id], b2c: false, b2b2c: false})
        } else if(id ==='b2c') {
            this.setState({ b2c: !this.state[id], b2b: false, b2b2c: false});
        } else if(id ==='b2b2c') {
            this.setState({ b2b2c: !this.state[id], b2b: false, b2c: false});
        } else {
            const userCategories = this.state.userCats;
            let indexInUserCats = userCategories.indexOf(id)

            if (indexInUserCats !== -1) {
                userCategories.splice(indexInUserCats, 1);
            } else if (indexInUserCats === -1 && userCategories.length < 10) {
                userCategories.push(id)
            }

            this.setState({ userCats: userCategories});
        }
    }

    async switchStep(e) {
        let move = e.target.id;
        const { fields, step } = this.state;

        if (move === 'next') {
            let moveToNext = true;
            for (let i = 0; i < fields[step].length; i ++) {
                let error = await this.validateInput(fields[step][i], i);

                if (error) {
                    moveToNext = false;
                }
            }
            if (moveToNext) {
                this.setState({
                    animation: 'animate__fadeInUp',
                    step: this.state.step+1
                }, () => setTimeout(() => this.setState({animation: ''}), 1000)); 
            }
        } else if (move === 'back') {
            this.setState({
                animation: 'animate__fadeInDown',
                step: this.state.step-1
            }, () => setTimeout(() => this.setState({animation: ''}), 1000));
        }   
    }

    displayError = (boolean, index) => {
        this.setState({
            fields: update(this.state.fields, {
                [this.state.step]: {
                    [index]: {
                        "showError": {
                            $set: boolean
                        } 
                    }
                }
            })
        })
    }

    async validateInput(e, index) {
        let { type, id } = 0;
        if (e.target) {
           type = e.target.type;
           id = e.target.id;
        } else {
            type = e.type;
            id = e.id;
        }

        const { b2b, b2c, b2b2c, hardware, software, nonTech, userCats, oneLiner } = this.state;

        if (type === 'email') {
            const validationResult = validate(this.state, constraints); // returns a message if !valid

            if (validationResult) {
                this.displayError(true, index);
                return true;
            } else {
                this.displayError(false, index);
            }

        } else if (id === 'customerType') {
            if (b2b === false && b2c === false && b2b2c === false) {
                this.displayError(true, index);
                return true;
            } else {
                this.displayError(false, index);
            }

        } else if (id === 'productType') {
            if (hardware === false && software === false && nonTech === false) {
                this.displayError(true, index);
                return true;
            } else {
                this.displayError(false, index);
            }

        } else if (id === 'categories') {
            const numOfCats = Object.keys(userCats).length;
            if (numOfCats < 2 || numOfCats > 10) {
                this.displayError(true, index);
                return true;
            } else {
                this.displayError(false, index);
            }

        }
        else if(id === 'oneLiner'){
            if(oneLiner.length < 8){
                this.displayError(true,index)
                return true;
            } else {
                this.displayError(false, index)
            }
        }
        else if (type === 'text' || id === 'location' || id === 'preMoneyValuation' || id === 'businessType') {
            if (this.state[id] === '') {
                this.displayError(true, index);
                return true;
            } else {
                this.displayError(false, index);
            }
        }
    }

    onEnterPress = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter' && this.state.step !== this.state.fields.length-1) {
          event.preventDefault();
          event.stopPropagation();
          const e = {target: {id: 'next'}}
          this.switchStep(e);
        } else if (event.key === 'Enter' && this.state.step === this.state.fields.length-1) {
            event.preventDefault();
            event.stopPropagation();
            this.submitForm()
        }
    }

    handleSignificantCats(e ) {
        e.preventDefault();

        const sigCats = this.state.significantCats;
        let indexInSigCats = sigCats.indexOf(e.target.id)

        indexInSigCats !== -1 ? sigCats.splice(indexInSigCats, 1) : sigCats.push(e.target.id);
        
        this.setState({ significantCats: sigCats});
    }

    async submitForm() {
        const { fields, step } = this.state;
        
        let submit = true
        for (let i = 0; i < fields[step].length; i++) {
            let error = await this.validateInput(fields[step][i], i);

            if (error) {
                submit = false;
            }
        }
        if (submit === false) {
            return;
        }

        const url = 'https://vcmatch-server.herokuapp.com';
        //const url = 'http://localhost:3001';

        const userInfo = {
            company: this.state.company,
            oneLiner: this.state.oneLiner,
            location: this.state.location,
            email: this.state.email,
            preMoneyValuation: this.state.preMoneyValuation,
            b2b: this.state.b2b,
            b2c: this.state.b2c,
            b2b2c: this.state.b2b2c,
            hardware: this.state.hardware,
            software: this.state.software,
            nonTech: this.state.nonTech,
            userCats: this.state.userCats,
            sigCats: this.state.significantCats,
            businessType: this.state.businessType
        }

        // axios.post(`${url}/airtable/list`, userInfo)
        // .then( top10 => {
        //     console.log(top10.data.strategists, top10.data.generalists);
        // })

        this.setState({ loading: true }, () => {
            axios.post(`${url}/airtable/email`, {email: userInfo.email})
            .then( emailInDB => {
                if (emailInDB.data) {
                    // send to an error page
                    this.props.history.push(`/error`);
                } else {
                    // generate top 30 VCs, return top 15, and POST to airtable all results and data
                    axios.post(`${url}/airtable/list`, userInfo)
                        .then( VCfirms => {
                            this.props.history.push({
                                pathname: '/results',
                                state: { 
                                    strategists: VCfirms.data.strategists, 
                                    generalists: VCfirms.data.generalists, 
                                    companyName: this.state.company,
                                    geography: VCfirms.data.geography
                                 }
                            })
                        })
                        .catch((err) => {
                            console.log(err);
                        });
                }
            })
            .catch((err) => {
                console.log(err);
            });
        });
    }

    render() {
        const { animation, step, fields, loading, userCats, significantCats, location } = this.state;

        return (
            <div className='flex-fixed-width-item'>
                <div className='title' onClick={() => this.props.history.push('/')}>
                    <h4 className='first'>VC</h4> <h4 className='second'>Rank</h4>
                </div>

                <div className={`form animate__animated ${animation}`} onKeyDown={e => this.onEnterPress(e)}>
                    <h4 className='header'>
                        <span className='step'>{step+1} </span> <i className="fa fa-arrow-right"></i> {fields[step][0].header}
                    </h4>

                    {loading 
                        ? 
                        <div>
                            <div className="meter">
                                <span><span className="progress"></span></span>
                            </div>
                            <p>Getting your results...</p>
                            <i className="fa fa-spinner"></i>
                        </div>
                        :
                        <div>
                            {step === 4 
                                ?
                                <CategoryRank 
                                    userCats={userCats}
                                    significantCats={significantCats}
                                    handleSignificantCats={this.handleSignificantCats}
                                />
                                :
                                <Form 
                                    fields={fields[step]} 
                                    handleInput={this.handleInput}
                                    handlePreMoneyValuation={this.handlePreMoneyValuation} 
                                    toggleCheckbox={this.toggleCheckbox} 
                                    state={this.state} 
                                    validateInput={this.validateInput} 
                                    displayError={this.displayError} 
                                />
                            }

                            { (fields[step][0].id === 'categories' || fields[step][0].id === 'preMoneyValuation' || (fields[step][0].id === 'categoryOrder' && userCats.length > 7)) &&
                                <div className="chevron-down"><i className="fa fa-chevron-down"></i></div>
                            }

                            <div className='row justify-content-around align-content-center'>
                                {step !== 0 &&
                                    <button className='btn button-border' id='back' onClick={this.switchStep}>
                                        Previous
                                    </button>
                                }
                                {step !== fields.length-1 &&
                                    <button className='btn button-border' id='next' onClick={this.switchStep}>
                                        Next
                                    </button>
                                }
                                {step === fields.length-1 &&
                                    <button className='btn button-border' id='submit' onClick={this.submitForm}>
                                        Submit
                                    </button>
                                }
                            </div>

                        </div>
                    }
                </div>

                <div className={step === 2 ? 'category-fix-container' : 'progressbar-fixed-container'}>
                    <div className='progressbar-container'>
                        {fields.map((field, index) => (
                            <div 
                            key={index}
                            className={index <= step ? 'progressbar filled' : 'progressbar'}
                            >
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        )
    }
}

export default InvestorList;