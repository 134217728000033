import React, {Component} from 'react'
import '../../styles/InvestorList.css';
import '../../../node_modules/animate.css';
import '../../styles/App.scss';
import '../../styles/Welcome.css'
import example from './vc-rank-example.xlsx';

class Welcome extends Component {
    constructor(props){
        super(props)
        this.state = {
            beginSurvey: false,
            startupName: '',
            displayError: false,
        }
        this.onEnterPress = this.onEnterPress.bind(this);
    }

    beginSurvey(){
        this.setState({
            beginSurvey: true
        })
    }
    handleInputChange(event){
        this.setState({
            displayError: false,
            startupName: event.target.value
        })
    }

    onEnterPress = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submitName()
        }
    }

    submitName(){
        if(this.state.startupName.length > 2){
            this.props.history.push({
                pathname: '/investorForm',
                state: { startupName: this.state.startupName }
              })
        } else {
            this.setState({
                displayError: true
            })
        }
    }

    goToAirTable(){
        this.props.history.push({ pathname: '/privacy-policy'})
    }

    render(){
        return (
            <div className='flex-fixed-width-item'>
                <div className='form animate__animated animate__fadeIn animate__slow' onKeyDown={e => this.onEnterPress(e)}>
                    <div className='row justify-content-center'>
                        <div>
                            <h1 className='first'>VC</h1> <h1 className='second'>Rank</h1>
                        </div>

                        <div>
                            <p className='subheader'>A List of Venture Firms for your Startup’s Next Round.</p>
                            <p className='bullet-text'>Fast. Tailored. Comprehensive.</p>
                        </div>
                        {this.state.beginSurvey ?
                            <div className='form animate__animated animate__fadeIn animate__slow'>
                                <h3 className='welcomeButtonHeader'>What is the name of your startup?</h3>
                                <input 
                                    className='startupNameInput'
                                    placeholder={"Type your answer here"} 
                                    onChange={this.handleInputChange.bind(this)}
                                    value={this.state.startupName}>
                                </input>
                                {this.state.displayError &&
                                    <p className='errorMessage'>Please enter your startup's name</p>
                                }
                                <button className='submitButton button-border btn' onClick={this.submitName.bind(this)}>
                                    <div>
                                        Begin
                                    </div>
                                </button>

                             </div>
                            :
                        <div>
                            <h3 className='welcomeButtonHeader'>Startup Founders</h3>
                                <button className='welcomeButton button-border btn' onClick={this.beginSurvey.bind(this)}>
                                    <div>
                                        Start
                                    </div>
                                </button>
        
                            <p className='investor-text'>If you are an investor <a className='investor-link' href='https://airtable.com/shrFSJt42yjh0XIv1' target='_blank' rel='noopener noreferrer'>click here</a> to ensure startups find you.</p>

                            <p className='spreadsheet-text'>
                                What do results look like?
                                <a 
                                  className='spreadsheet' 
                                  href={example} 
                                  download={'vc-rank-example.xlsx'}
                                >
                                  Download Sample
                                </a>
                            </p>
                        </div>
                        }
                    </div>
                </div>
            </div>
        )
    }
}

export default Welcome;