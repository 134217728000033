import React from 'react';
import '../../styles/CategoryRank.css';

const CategoryRank = ({ userCats, significantCats, handleSignificantCats }) => {
    return (
        <div className='inputField'>
            <div className='list-group cat-container'>
                {userCats.map(category => (
                    <div key={category}>
                        <div 
                          className={significantCats.indexOf(category) !== -1 ? 'list-group-item active-item' : 'list-group-item'}
                          id={category}
                          onClick={(e) => handleSignificantCats(e)}
                        >
                          {category}
                        </div>
                    </div>
                ))}
            </div>
        </div>
    )
}

export default CategoryRank
