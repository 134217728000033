import React from 'react'
import errorIcon from '../../images/error.png';
import '../../styles/InvestorList.css';
import '../../styles/ErrorPage.css';
import '../../../node_modules/animate.css';

const ErrorPage = (props) => {
    return (
        <div className='flex-fixed-width-item'>
            <div className='title' onClick={() => props.history.push('/')}>
                <h4 className='first'>VC</h4> <h4 className='second'>Rank</h4>
            </div>

            <div className='form animate__animated animate__fadeIn animate__slow'>
                <img className="error-icon" src={errorIcon} alt='Error' />
                <h2 className='header' >Oops!</h2>
                <div className='row justify-content-center'>
                    <div>
                        <p className='error-text'>Looks like you've previously requested a customized VC list</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ErrorPage;