import React from 'react'
import '../../styles/InvestorList.css';
import '../../styles/Success.css';
import '../../../node_modules/animate.css';

const Success = (props) => {
    return (
        <div className='flex-fixed-width-item'>
            <div className='title' onClick={() => props.history.push('/')}>
                <h4 className='first'>VC</h4> <h4 className='second'>Rank</h4>
            </div>

            <div className='form animate__animated animate__fadeIn animate__slow'>
                <i className="im im-check-mark-circle-o"></i>
                <h2 className='header' >Success!</h2>
                <div className='row justify-content-center'>
                    <div>
                        <p className='success-text'>We will email you your customized VC list shortly!</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Success
