export const businessStageDescriptions = {
    'Enterprise SaaS': [ {stage: 'stage1', title: 'Idea / Hypothesis', description: 'Pre launch or alpha/beta released. Monthly revenue between 0 - $5,000.'}, 
    {stage: 'stage2', title: 'MVP with traction', description: 'Pilot phase. 2 - 10 customers. Monthly revenue is between $5,000 - $25,000.'},
    {stage: 'stage3', title: 'Commercial traction', description: 'Early commercialization. Monthly revenue is between $25,000 - $50,000.'},
    {stage: 'stage4', title: 'Traction with early evidence of PMF', description: 'Monthly revenue is between $50,000 - $100,000.'},
    {stage: 'stage5', title: 'Clear PMF', description: 'Monthly revenue is between $100,000 - $200,000.'},
    {stage: 'stage6', title: 'Growth', description: 'Monthly revenue is $200,000+.'},
    ],
    'Consumer SaaS': [{stage: 'stage1', title: 'Idea / Hypothesis', description: 'Pre launch or alpha/beta released. Annual Revenue is less than $25,000.'}, 
        {stage: 'stage2', title: 'MVP with traction', description: 'Pilot phase. 2 - 10 customers. Annual revenue is ~$200,000.'},
        {stage: 'stage3', title: 'Commercial traction', description: 'Early commercialization. Annual revenue is ~$500,000.'},
        {stage: 'stage4', title: 'Traction with early evidence of PMF', description: 'Annual revenue is ~$2,000,000.'},
        {stage: 'stage5', title: 'Clear PMF', description: 'Annual revenue is ~$4,000,000+.'},
        {stage: 'stage6', title: 'Growth', description: 'Annual revenue is greater than $8,000,000.'}, 
    ],
    'Marketplace': [ {stage: 'stage1', title: 'Idea / Hypothesis', description: 'Pre launch or alpha/beta released. Monthly GMV is between $0 - $20,000.' }, 
        {stage: 'stage2', title: 'MVP with traction', description: 'Monthly GMV is between $20,000 - $50,000.'},
        {stage: 'stage3', title: 'Commercial traction', description: 'Monthly GMV is between $50,000 - $250,000.'},
        {stage: 'stage4', title: 'Traction with early evidence of PMF', description: 'Monthly GMV is between $250,000 - $500,000.'},
        {stage: 'stage5', title: 'Clear PMF', description: 'Monthly GMV is between $500,000 - $2,500,000.'},
        {stage: 'stage6', title: 'Growth', description: 'Monthly GMV is greater than $2,500,000+.'}, 
    ],
    'DeepTech / Hardware': [{stage: 'stage1', title: 'Idea / Hypothesis', description: 'Technology in development with customer applications identified.'}, 
            {stage: 'stage2', title: 'Prototype', description: 'Prototype has been built, and purchase intent validated.'},
            {stage: 'stage3', title: 'MVP', description: 'Pilot phase with 2 - 10 customers. Monthly revenue ~$10,000.'},
            {stage: 'stage4', title: 'Market validation', description: 'Early commercialization phase. Monthly revenue is ~$25,000.'},
            {stage: 'stage5', title: 'Early evidence of PMF', description: 'Monthly revenue is ~$50,000.'},
            {stage: 'stage6', title: 'Clear PMF', description: 'Monthly revenue is ~$100,000+'}, 
    ],
    'Consumer User Growth': [{stage: 'stage1', title: 'Idea / Hypothesis', description: 'Pre launch or alpha/beta released. Between 0 - 500 daily active users.'}, 
        {stage: 'stage2', title: 'MVP with traction', description: 'Between 500 - 5,000 daily active users.'},
        {stage: 'stage3', title: 'Proof of concept', description: 'Between 5,000 - 50,000 daily active users.'},
        {stage: 'stage4', title: 'Early evidence of PMF', description: 'Between 50,000 and 250,000 daily active users.'},
        {stage: 'stage5', title: 'Clear PMF', description: 'Between 250,000 and 1,000,000 daily active users.'},
        {stage: 'stage6', title: 'Growth', description: 'Greater than 1,000,000 daily active users.'}, 
    ],
}

export default businessStageDescriptions;