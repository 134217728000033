import React, {Component} from 'react'
import FirmDetails from './FirmDetails';
import '../../styles/InvestorList.css';
import '../../../node_modules/animate.css';
import '../../styles/Results.css';
import closeIcon from '../../images/closeIcon.png';
import '../../styles/FirmDetails.css';
import axios from 'axios';

class Results extends Component {
    constructor(props){
        super(props)
        this.state = {
            viewing: 'strategists',
            firmList: this.props.location.state.strategists,
            showModal: false,
            sendResultsModal: false,
            firmToDisplay: 0,
        }
        this.showDetails = this.showDetails.bind(this);
        this.hideDetails = this.hideDetails.bind(this);
        this.switchFirmType = this.switchFirmType.bind(this);
        this.showSendResultsModal = this.showSendResultsModal.bind(this);
        this.hideSendResultsModal = this.hideSendResultsModal.bind(this);
        this.updateAirtable = this.updateAirtable.bind(this);
    }

    showDetails(e, i) {
        this.setState({showModal: true, firmToDisplay: i});
    }

    hideDetails() {
        this.setState({showModal: false});
    }

    showSendResultsModal(e) {
        this.setState({sendResultsModal: true})
    }

    hideSendResultsModal(e) {
        this.setState({sendResultsModal: false})
    }

    updateAirtable() {
        const url = 'https://vcmatch-server.herokuapp.com';
        // const url = 'http://localhost:3001';
        axios.put(`${url}/airtable/send-results`, {companyName: this.props.location.state.companyName})
            .then( stuff => {})
            .catch((err) => {
                console.log(err);
            });
    }

    switchFirmType(e) {
        e.preventDefault();
        if (e.target.id === 'generalists') {
            this.setState({ firmList: this.props.location.state.generalists, viewing: 'generalists'});
        } else if (e.target.id === 'strategists') {
            this.setState({ firmList: this.props.location.state.strategists, viewing: 'strategists'});
        } else if (e.target.id === 'geography'){
            this.setState({ firmList: this.props.location.state.geography, viewing: 'geography'});
        }
    }

    render() {
        const { firmList, showModal, firmToDisplay, viewing, sendResultsModal } = this.state;
        
        return (
            <div className='animate__animated animate__fadeIn animate__slow mobile-margin'>
                <div className='title' onClick={() => this.props.history.push('/')}>
                    <h4 className='first'>VC</h4> <h4 className='second'>Rank</h4>
                </div>

                {showModal && 
                    <FirmDetails 
                      firm={firmList[firmToDisplay]} 
                      viewing={viewing} 
                      hideDetails={this.hideDetails}
                    /> 
                }

                {sendResultsModal && 
                    <div>
                        <div className='modal-cover'>
                        </div>
                        <div className='modal-card send-results-card'>
                            <img src={closeIcon} className='close-icon' alt='close modal' onClick={(e) => this.hideSendResultsModal(e)} />
                            
                            <div className='send-results-text'>
                                <p>We will email you a spreadsheet with your results within the next 24 hours.</p>
                                <div>
                                    <button className='send-results-btn button-border btn' 
                                        onClick={() => this.props.history.push({ 
                                                        pathname: `/share`, 
                                                        state: {companyName: this.props.location.state.companyName} 
                                        })}
                                    >
                                        <i className="fa fa-share-square"></i>
                                        <div className='share-btn-text'>
                                             Want 2x more VC firms that are a fit?
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <div>
                    <h2 className='results-title'>Your Top 20 VCs</h2>
                </div>

                <div className='tab-container'>
                        <h4
                          className={viewing === 'strategists' ? 'results-tab active-tab' : 'results-tab'}
                          id='strategists' 
                          onClick={(e) => this.switchFirmType(e)}
                        >
                            Specialists
                        </h4>

                        <h4
                          className={viewing === 'generalists' ? 'results-tab active-tab' : 'results-tab'}
                          id='generalists' 
                          onClick={(e) => this.switchFirmType(e)}
                        >
                            Generalists
                        </h4>
                        <h4
                          className={viewing === 'geography' ? 'results-tab active-tab' : 'results-tab'}
                          id='geography' 
                          onClick={(e) => this.switchFirmType(e)}
                        >
                            Geography
                        </h4>
                </div>

                <div className='results-table'>
                    <div className={viewing === 'strategists' ? 'table-header' : 'table-header general-width'}>
                       <div className='col0 rank-header'>Rank</div>
                       <div className='col1'>Firm</div>
                       <div className='col2'>Location</div>
                       <div className='col3'>Website</div>
                       <div className='col4'>Crunchbase Link</div>
                       <div className='col5'>Lead</div>
                       <div className='col6'>Check Size</div>
                       <div className='col7'>Strategic Category Fits</div>
                       {viewing === 'strategists' && 
                        <div>
                            <div className='col7'>Opportunistic Category Fits</div>
                        </div>
                       }
                       <div className='col8'>One Liner</div>
                    </div>

                    <div className='table-body'>
                        {firmList.map( (firm, index) => (
                            <div 
                              className={viewing === 'strategists' ? 'table-row' : 'table-row general-width'}
                              key={index} 
                              onClick={ (e) => this.showDetails(e, index)}
                            >
                                <div className='col0'>{index+1}</div>
                                <div className='col1'>{firm['VC Firm Name']}</div>
                                <div className='col2'>{firm.Location ? firm.Location : 'N/A'}</div>
                                <div className='col3'>{firm.Website ? firm.Website : 'N/A'}</div>
                                <div className='col4'>{firm['Crunchbase Link'] ? firm['Crunchbase Link'] : 'N/A'}</div> 
                                <div className='col5'>{firm.leads ? 'Yes' : 'No'}</div>
                                <div className='col6'>{firm['Check Size'] ? firm['Check Size'] : 'N/A'}</div>
                                <div className='col7'>{firm.stratMatchingCats}</div>
                                {viewing === 'strategists' && 
                                    <div>
                                        <div className='col7'>{firm.oppMatchingCats}</div>
                                    </div>
                                }
                                <div className='col8'>{firm['One Liner'] ? firm['One Liner'] : 'N/A'}</div>
                            </div>
                        ))}
                    </div>
                </div>

                <div>
                    <button className='get-spreadsheet-btn button-border btn' 
                        onClick={(e) => {
                            this.showSendResultsModal(e)
                            this.updateAirtable()
                        }}
                    >
                        <i className="fa fa-share-square"></i>
                        <div className='share-btn-text'>
                            Send me my results!
                        </div>
                    </button>
                </div>

            </div>
        )
    }
}

export default Results;
