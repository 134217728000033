import React from 'react'
import closeIcon from '../../images/closeIcon.png';
import '../../styles/FirmDetails.css';

const FirmDetails = ({ firm, viewing, hideDetails }) => {
    return (
        <div>
            <div className='modal-cover'>
            </div>
            <div className='modal-card'>
                <img src={closeIcon} className='close-icon' alt='close modal' onClick={hideDetails} />
                
                <div className='firm-details'>
                    <h3 className='detail-firm-name'>{firm['VC Firm Name']}</h3>
                    <div className='detail-location' >{firm.Location ? firm.Location : 'N/A'}</div>
                    <div className='detail-oneLiner' >{firm['One Liner'] ? firm['One Liner'] : 'N/A'}</div>

                    <div className='detail-name'>Website</div>
                    <div className='detail-info' >
                        {firm.Website ? 
                            <a className='detail-link' href={`//${firm.Website}`} target='_blank' rel='noopener noreferrer'>
                                {firm.Website}
                            </a>
                            : 'N/A'}
                    </div>

                    <div className='detail-name'>CrunchBase</div>
                    <div className='detail-info' >
                        {firm['Crunchbase Link'] ? 
                            <a className='detail-link' href={`//${firm['Crunchbase Link']}`} target='_blank' rel='noopener noreferrer'>
                                {firm['Crunchbase Link']}
                            </a>
                            : 'N/A'}
                    </div>

                    <div className='detail-name'>Lead</div>
                    <div className='detail-info' >{firm.leads ? 'Yes' : 'No'}</div>

                    <div className='detail-name'>Check Size</div>
                    <div className='detail-info' >{firm['Check Size'] ? firm['Check Size'] : 'N/A'}</div>
                    
                    {viewing === 'strategists' && 
                        <div>
                            <div className='detail-name'>Strategic Category Fits</div>
                            <div className='detail-info' >{firm.stratMatchingCats}</div>

                            <div className='detail-name'>Opportunistic Category Fits</div>
                            <div className='detail-info' >{firm.oppMatchingCats}</div>
                        </div>
                    }
                </div>
            </div>

        </div>
    )
}

export default FirmDetails
