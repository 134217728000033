import React, {Component} from 'react'
import '../../styles/Share.css';
import '../../../node_modules/animate.css';
import twitter from '../../images/twitter.png';
import linkedin from '../../images/linkedin.png';
import upload from '../../images/upload.png';
import axios from 'axios';

class Share extends Component {
    constructor(props){
        super(props);
        this.state = {
            showInputField: false,
            shared_link: '',
            displayError: false,
        };
        this.displayInputField = this.displayInputField.bind(this);
        this.handleInput = this.handleInput.bind(this);
        this.onEnterPress = this.onEnterPress.bind(this);
        this.submitLink = this.submitLink.bind(this);
    }

    displayInputField() {
        this.setState({ showInputField: true })
    }

    handleInput(e){
        this.setState({
            displayError: false,
            shared_link: e.target.value
        })
    }

    isURL(str) {
        try {
            new URL(str);
          } catch (_) {
            return false;  
          }
        return true;
    }

    onEnterPress = (event) => {
        // 'keypress' event misbehaves on mobile so we track 'Enter' key via 'keydown' event
        if (event.key === 'Enter') {
            event.preventDefault();
            event.stopPropagation();
            this.submitLink()
        }
    }

    submitLink() {
        let isURL = this.isURL(this.state.shared_link);
        if (!isURL) {
            this.setState({ displayError: true })
        } else {
            const url = 'https://vcmatch-server.herokuapp.com';

            const putObj = {
                companyName: this.props.location.state.companyName,
                shared_link: this.state.shared_link
            }

            axios.put(`${url}/airtable/sharedlink`, putObj)
            .then( res => {
                //console.log(res.data);
            })
            .catch((err) => {
                console.log(err);
            });
            this.props.history.push(`/success`);
        }
    }

    render() {
        const { showInputField, shared_link, displayError } = this.state;
        const toolURL = 'www.vc-rank.com';
        
        return (
            <div className='flex-fixed-width-item'>
                <div className='title' onClick={() => this.props.history.push('/')}>
                    <h4 className='first'>VC</h4> <h4 className='second'>Rank</h4>
                </div>
    
                <div className='form animate__animated animate__fadeIn animate__slow'>
                    <h2 className='share-header' >Share VC Rank</h2>
                    <p className='share-subheader'>Help us spread the word and we'll shoot you 60 VC firms that fit your critera.</p>
                    <div className='share-text'>
                        <p className="share-step">1. Share VC Rank on LinkedIn or Twitter.</p>
                        <p className="share-step">2. Upload a link to your post.</p>
                        <p className='share-step no-bottom-margin' >3. We will send you a spreadsheet with 60 results within 24 hours</p>
                    </div>
    
                    <div className='row justify-content-center'>
                        <div>
                            <div>
                                <a className='' href={`http://www.linkedin.com/shareArticle?mini=true&url=${toolURL}`} target='_blank' rel='noopener noreferrer'>
                                    <img className="share-icons" src={linkedin} alt='linkedin'></img>
                                </a>
                                <a className='' href={`http://twitter.com/share?url=${toolURL}&text=VC Rank generated a list of the 50 best-fit investors for my startup based on stage and sector. Get your startup’s custom list at: &hashtags=VentureCapital,fundraising @NewStackVc`} target='_blank' rel='noopener noreferrer'>
                                    <img className="share-icons" src={twitter} alt='twitter'></img>
                                </a>
                            </div>
                        </div>
                    </div>
    

                    {showInputField ? 
                        <div className='row justify-content-center'>
                            <div className='form animate__animated animate__fadeInUp' onKeyDown={e => this.onEnterPress(e)}>
                                <input 
                                    className='text-inputs'
                                    placeholder='Paste link to your shared post here' 
                                    onChange={this.handleInput}
                                    value={shared_link}>
                                </input>
                                {displayError && <p className='error'>Enter an appropriate link</p>}

                                <div>
                                    <button className='link-submit-btn button-border btn' onClick={this.submitLink}>
                                        Get your Results!
                                    </button>
                                </div>
                            </div>
                        </div>
                        :
                        <button className='upload-link-btn button-border btn' onClick={this.displayInputField}>
                            <img className='upload-img' src={upload} alt='upload link' />
                            <div>
                                <p className='upload-text-1'>Upload a link to your post.</p>
                                <p className='upload-text-2'>We will send you your spreadsheet within 24 hours</p>
                            </div>
                        </button>
                    }


                </div>
            </div>
        )
    }
}

export default Share;